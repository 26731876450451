import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AppContext from './AppContext';
import Error403 from './components/error/Error403';
import Error500 from './components/error/Error500';
import Error404 from './components/error/Error404';

const ErrorHandler = () => {
  const history = useHistory();
  const { appState, setAppState } = useContext(AppContext);

  // Reset error status code upon navigation.
  useEffect(() => {
    if (!appState || !setAppState) {
      return;
    }

    const listener = history.listen(() => {
      setAppState({ ...appState, errorStatusCode: null });
    });

    return listener;
  }, []);

  return (
    <>
      {appState && (
        <>
          {(appState.errorStatusCode === 403 ||
            appState.errorStatusCode === 401) && <Error403 />}
          {appState.errorStatusCode === 500 && <Error500 />}
          {appState.errorStatusCode === 404 && <Error404 />}
        </>
      )}
    </>
  );
};

export default ErrorHandler;
